.placeholder {
   display: flex;
   align-items: center;
   justify-content: center;
   height: calc(var(--vh, 1vh) * 100 - 90px); 
   width: 100%; 
 }
 
 .loader {
   border: 8px solid #f3f3f3; 
   border-top: 8px solid #8b8a8a; 
   border-radius: 50%;
   width: 60px;
   height: 60px;
   animation: spin 2s linear infinite;
 }
 
 @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
 }
 