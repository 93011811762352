@import "../../scss/_variables";

.header {
  position: fixed;
  background-color: $black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 20px;
  height: 70px;
  

  &__logo {
    position: relative;
    z-index: 100000;
    margin: auto 0;

    h1 {
      cursor: pointer;
      font-family: 'DM Mono';
      font-size: 26px;
      color: $white; 
      font-weight: 400;

      @media (max-width: 1024px) {
        font-size: 30px;
      }
    }
  }

  &.active .header__logo h1 {
    color: $black; 
  }

  &__navbar {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 22px;
    cursor: pointer;
    transition: all $duration ease-in-out;

    @media (max-width: 1024px) {
      display: none;
    }

    &.active {
      display: none;
    }
  }

  &__button {
    color: $white;
    font-family: 'DM Mono';
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__language {
    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.burgerMenu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 24px; 
  cursor: pointer;
  z-index: 1001;

  @media (max-width: 1024px) {
    display: flex;
  }

  &.active .burgerBar {
    background-color: $black;
  }

  &.active .bar1 {
    transform: rotate(45deg) translate(7.5px, 7.5px);
  }

  &.active .bar2 {
    opacity: 0;
  }

  &.active .bar3 {
    transform: rotate(-45deg) translate(7.5px, -7.5px);
  }
}

.burgerBar {
  width: 100%;
  height: 3px;
  background-color: $white;
  transition: all $duration ease-in-out;
}

.fullscreenMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn $duration ease-in-out;

  &__content {
    text-align: center;
    margin: 0 auto;
  }

  &__link {
    display: block;
    font-family: 'DM Mono';
    font-size: 24px;
    color: $black;
    text-decoration: none;
    margin: 20px 0;
    transition: color $duration ease-in-out;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
